import React from "react";

const FormErrorMessage = props => {
    const {
        error
    } = props;

    const containsLoginError =
        error.indexOf('User session is Ended') !== -1 ||
        error.indexOf("Parameter 'retailerId' is null") !== -1;

    return (
        <div className="form__error">
            <i className="icon icon-alert-triangle" />
            <div className="form__error--message">
                <div>
                    {error}
                    {containsLoginError && (
                        <>
                            <br />
                            <a href="/" target="_blank" className="form__error--message-link">Please login again</a> in the new tab, selected a retailer and submit this form again.
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
export default FormErrorMessage;
